.createplayerContainer{
    overflow: hidden;
}


.inputlabelName {
    text-align: left !important;
}

.labelVisible {
    opacity: 1;
}

.topCDivDesign {
    height: 100%;
    /* align-items: center; */
    justify-content: center;
    padding-left: 2%;
    padding-right: 2%;
}
.profileWrapper{
    margin-top: 50px;
}
.currentStock{
    background-color: #033872 !important;
    border-radius: 10px !important;
    padding: 20px;
    margin-bottom: 10px;
}
.newStockV{
    background-color: #7acb38 !important;
    border-radius: 10px !important;
    padding: 10%;
}

.oldStockV{
    background-color: #cbb538 !important;
    border-radius: 10px !important;
    padding: 10%;
}
.colorSecon{
    color:  var(--secondary) !important;
}
.paddingR{
    padding-right: 15px;
}
.paddingL{
    padding-left: 15px;
}
.playerPer {
    margin-top: 40px !important;
}

.topDivSpace {
    margin-top: 0px !important;
}

.playerPerH {
    font-size: 20px !important;
    font-weight: 600 !important;
}

.textLeft {
    display: flex;
    align-items: center;
    justify-content: left;
}

.textRight {
    display: flex;
    align-items: center;
    justify-content: right;
}

tbody tr:nth-child(even) {
    background: #ededed 0% 0% no-repeat padding-box;
}

tbody tr:nth-child(odd) {
    background: #f1f5f9 0% 0% no-repeat padding-box;
}

.backDGreay {
    background-color: #3f576f;
    height: 148px;
    color: var(--secondary);
    padding: 22px;
    font-weight: 600;
}

.backGreay {
    background-color: #eef2fe;
    height: 130px;
    color: var(--secondary);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 40px;
}
.profilePic{
    position: relative;
    height: 120px;
    width: 120px;
    border-radius: 100px;
    top: 40%;
    left: 29%;
}
.performance-table-input {
    background-color: var(--secondary);
    height: 40px;
    width: 80%;
    border-radius: 5px;
    font-weight: 600 !important;
}

.performance-table-input:before {
    border-bottom: none !important;
}

.performance-table-input input {
    text-align: center;
}
.stockEvualation{
    color: #1a1a1a;
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-top: 15% !important;
    margin-bottom: 15% !important;
}
.playerStatic {
    width: 150px;
}
.stockEvualationP{
    color: #1a1a1a;
    font-size: 35px !important;
    font-weight: 600 !important;
}
.stockPriceDetails{
    color: #1a1a1a;
    font-size: 20px !important;
    font-weight: 600 !important;
}
.UpPlayerphoto {
    background-color: #005eff !important;
    padding: 10px 20px !important;
    font-size: 12px !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
}

.cardContent {
    padding: 0px !important;
}
.threePage{
    width: 100%;
    border-radius: 10px !important;
}
.submitCass{
    width: 100%;
    background-color: #1976d2 !important;
    padding: 10px 20px !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    margin-bottom: 30px !important;
    margin-top: 40px !important;

}
.addMorePlayer{
    width: 100%;
    background-color: #1976d2;
    padding: 10px 20px !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
}
.fileInput{
    position: absolute !important;
    width: 100%;
    opacity: 0;
}
.imageResponse{
    width: 100% !important;
    height: 100% !important;
    border-radius: 80px;
}

.submit-container{
    margin-top: 5% !important;
}

