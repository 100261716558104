.search-input-section {
    margin-bottom: 20px !important;
}

.search-input-section .search-input-icon {
    position: absolute;
}

.search-input-section .search-input-icon {
    color: rgba(19, 49, 75, 0.4) !important;
    padding: 13px;
}

.search-input-section .search-field-input {
    width: 32%;
    text-align: left;
    background: #D4DFFC66;
    border: none;
    border-radius: 5px;
    height: 50px;
    padding-left: 45px;
}

.search-input-section .search-field-input::placeholder {
    color: #13314B66;
    font-size: 17px;
}

.search-input-section .search-field-input:focus {
    outline: none !important;
}
.search-field-input{
    width: 62% !important;
}