.myContainer {
    display: flex;
    min-height: 100vh;
}

.main-body {
    width: 100%;
    padding: 1%;
    background: var(--background);
}

.main-content-wrapper{
    padding: 2%;
    min-height: 100vh;
    background: var(--content-background);
    border-radius: 30px;
}

.main-content {
   
    box-shadow: 0 1px 2px 1px rgba(151, 150, 150, 0.1);
    background: white;
    padding: 1%;
    margin-top: 1%;
    border-radius: 30px;
}

.sidebar {
    background: var(--background);
    width: 17em;
    transition: width .2s ease-in;
    box-sizing: border-box;
    padding-top: 5%;
    position: sticky;
    position: -webkit-sticky;
    height: auto;
}

.top_section {
    display: flex;
    align-items: center;
    padding: 20px 10px;
}

.logo {
    font-size: 20px;
}

.bars {
    display: flex;
    font-size: 15px;
    margin-left: 50px;
    color: lightskyblue;
}

.link {
    display: flex;
    color: white;
    padding: 5%;
    width: 100%;
    font-weight: 400;
    transition: all 0.1s;
    text-decoration: none;
    margin-left: 5%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin-bottom: 8px;
}

.active {
    background-color: var(--content-background);
    color: var(--background);
    font-weight: 500;
    
}

.menu_section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    margin-left: 5px;
}

.icon {
    font-size: 14px;
    flex-grow: 1;
    width: 15%;
}

.image-icon {
    width: 25px;
    height: 25px;
}

.link_text {
    font-size: 17px;
    flex-grow: 1;
    width: 75%;
    line-height: 23px;
    padding-left: 5px;
}

.toggleIcon {
    font-size: 14px;
    flex-grow: 1;
    width: 5%;
}

.sidebar-org-name {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
    font-style: italic;
    box-shadow: 2px 2px 6px 2px #e1e1e1;
    border-radius: 10px;
    color: #1E3A8A;
}