.changePhoto {
    background-color: #3f576f !important;
    padding: 10px 20px !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    margin-top: 85px !important;
    color: #fff !important;
    border: 1px solid #fff !important;
}

.profilePicV {
    position: relative;
    height: 120px;
    width: 120px;
    border-radius: 100px;
    /* background-color: var(--secondary); */
    top: 82%;
    left: 28%;
    /* border: 4px solid #fff; */
}

.backGreayV {
    background-color: #eef2fe;
    height: 147px;
    color: var(--secondary);
    align-items: end;
    justify-content: center;
    padding-bottom: 40px;
}
.backDGreayV {
    background-color: #3f576f;
    height: 165px;
    color: var(--secondary);
    padding-top: 22px;
    font-weight: 600;
}

.backCrdName {
    background-color: #f6f8fe;
    margin-bottom: 5px !important;
    border-radius: 5px;
}

.textLeft {
    text-align: left !important;
}

.textRight {
    text-align: right;
}

.backgroundLB {
    background-color: #eef2fe;
}

.playerName {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 35px !important;
    letter-spacing: 0.03em !important;
    color: #1A1A1A;
}

.RondsName {
    font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 30px !important;
    line-height: 35px !important;
    letter-spacing: 0.001em !important;
    color: #1A1A1A;
    margin-bottom: 20px !important;
    text-align: center;
}

.paraGraph {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1ACC;
}

.playerPerfor {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.2px;
    color: #0A1F44;
    border-bottom: 2px solid #fff;
    border-top: 2px solid #fff;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    margin-top: 30px !important;
    padding-left: 20px !important;
}

.padTB {
    padding-top: 20px;
    padding-bottom: 20px;
}
.fontWeightB{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 21px;
    color: #013369;
    font-weight: 600 !important;
    margin-bottom: 15px !important;
}
.imageResponse{
    width: 100% !important;
    height: 100% !important;
    border-radius: 80px;
}
.padBottom {
    padding-bottom: 10px;
}
.imageLogo{
    width: 70%;
    height: 100%;
}
.imageDivi{
    height: 65%;
}
.cancelCss{
    padding: 10px 50px !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
    margin-right: 20px !important;
}
.editPlayer{
    background-color: #1976d2 !important;
    padding: 10px 60px !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}
.textCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
.paddingRT{
    padding-right: 20px;
}
.paddingLt{
    padding-left: 20px;
}
.padTop{
    padding-top: 20px !important;
}
.headFive{
    font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 21px !important;
    color: #0A1F44;
    margin-top: 12px !important;
}
.marginTopT{
    margin-top: 30px !important;
}
.marginBottom{
    margin-bottom: 10px !important;
}