html {background-color: #005AC108;}
body {background-color: #005AC108;}

.Login-Container {
    color: #D8EFFF42;
    padding-left: 0%;
    padding-right: 0%;
}

.Login-logo {
    height: 10vmin;
    pointer-events: none;
  }

.Login-div {
    display: flex;
    justify-content: center;
    top: 25%;
    position: relative;
}

.Login-paper {
    padding: 25px;
    width: 456px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20vh;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 5px;
    text-align: left;
}

.Login-label {
    font-size: 12px;
    text-align: left;
    color:#A9A9A9;
    position: absolute;
}
.textsLeft{
    text-align: left;
}
.Login-text {
    font-size: 12px;
    text-align: left;
    /* margin-top: 40px; */
    margin-top: 10px;
    position: absolute;
}

.Login-button {
    background-color:#005AC1;
    border-radius: 8px;
}

.Login-typo {
    font-size: 14px;
    cursor: pointer;
}

.Login-modal {
    position: 'absolute';
    transform: 'translate(-50%, -50%)';
    background-color: white;
    border: '2px solid #000';
    padding: 25px;
    height: 390px;
    width: 456px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20vh;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 5px;
    outline: 0;
}
.error-msg{
    color: red;
    font-size: 14px;
}