.modal-box{
    background-color: white;
    padding:1%;
    text-align: right;
    border-radius: 5px;
    outline: 0;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: 10%;
}

.modal-box-wrapper{
    background-color: white;
    padding: 5%;
    border-radius: 5px;
    outline: 0;
}

.modal-header-grid-wrapper-left{
    text-align: start;
}

.modal-header-grid-wrapper-right{
    text-align: end;
}