.HeaderMainContainer{
  width: 100%;
  text-align: center;
  z-index: 1000 !important;
}

.nav {
  background-color: var(--background) !important;
}



.grid_item_1{
  padding-left: 10px;
}

.grid_item_2{
  float: right !important;
  background-color:blanchedalmond ;
  
}

.grid_item_2_item{
  float: right !important;
  text-align: end !important;

}

.logo {
  width: 100px;
}

.togglebtnHamburger {
  outline: none;
  border: none;
  background-color: transparent;
}

.HamburgerIconimg {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border: none;
}

.searchbar {
  width: 400px;
  background: #eef2fe 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border:none !important;
}

.searchicon {
  margin-top: -5px;
}

.Headercontainercol1 .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  margin-top: -6px;
  font-size: 16px;
  margin-left: -8px;
}

.iconprofile {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.profile-details-wrapper{
  padding: 5px;
  min-width: 100px;
  text-align: right;
}

.profilename {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--secondary);
}
.roledescription{
font-style: italic;
font-weight: 400;
font-size: 14px;
text-align: right;

color: #DFE6EE;
}

@media only screen and (min-width: 0px) and (max-width: 1269px) {

  /* mobile css */
  .sidenavmobile {
    height: 100%;
    width: 0px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--primary);
    transition: 0.5s;
    padding-top: 60px;
    background: var(--background);
    ;
    overflow-y: scroll;
    z-index: 1000;
  }

  .sidenavmobile a {
    text-decoration: none;
    font-size: 22px;
    color: var(--secondary);
  }

  .closebtn {
    position: absolute;
    top: 2%;
    right: 5px;
  }

  .sidebardivMobile {
    margin-top: 15px;
  }

  .actionMobile {
    text-decoration: none;
    font-Weight: "bold" !important;
    color: var(--primary) !important;
    background: var(--secondary);
    width: 50vw;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: left;
    margin-left: 20px;
  }

  .inactionMobile {
    color: var(--secondary);
    text-decoration: none;
    font-Weight: "bold" !important;
    width: 50vw;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: left;
    margin-left: 20px;
  }
}

.logout-cls{
  cursor: pointer;
}