

.playerContainer {
  margin-top: 20px;
  
 
}

.tableHead {
  background-color: var(--background);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.colorWhite {
  color: var(--secondary) !important;
  text-align: center;
  border-left: 0.5px solid var(--app-blue);
  font-weight: 500 !important;
  font-size: 16px !important;
}
.view-player-ic{
    margin-right: 10px;
    cursor: pointer;
    color: #4A618E;
    font-size: 20px !important;
}

.edit-player-ic{
  margin-right: 10px;
  cursor: pointer;
  color: var(--app-blue);
  font-size: 20px !important;
}

.delete-player-ic{
  cursor: pointer;
  color: var(--app-red);
  font-size: 20px !important;
}

.imageIcon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-left: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 5px;
  margin-top: 2px;
}

.inlineFlex {
  display: inline-flex !important;
}

.topDivDesign {
  height: 100%;
  align-items: center;
  justify-content: space-between;;
  margin-top: 0px !important;
}

.textLeft {
  display: flex;
  align-items: center;
  justify-content: left;
}

.textRight {
  display: flex;
  align-items: center;
  justify-content: right;
}

.searchbarPlay {
  width: 400px;
  background: #eef2fe 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border:none !important;
}

.searchPlay {
  margin-top: -5px;
}

.textLeft .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  font-size: 16px;
  margin-left: -8px;
  padding: 0px 4px 7px 6px;
}

.textLeft .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
.LeagueClass {
  background-color: #F5761A;
  padding: 10px 20px !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
}
.FilterClass {
  background-color: #1976d2;
  padding: 10px 20px !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
}

.filterIcon {
  font-size: 20px !important;
  margin-right: 10px;
}

.createPlayer {
  background-color: #099885 !important;
  padding: 10px 20px !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
}

.paddingTopBottom {
  padding-bottom: 16px;
  padding-top: 0px !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rowdropdoencol {
  text-align: right;
}
.rowdropdoencol1 {
 display: flex;
 align-items: center;
 justify-content: right;
}

.rowdropdoenformcontrol {
  z-index: 1;
}
.player-tc-action{
  padding: 10px !important;
}

.player-typography{
  font-weight: 600 !important;
  padding: 5px;
}
.stock-value-tc{
  color: var(--app-blue) !important;
  font-weight: 600 !important;
}